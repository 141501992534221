import { createSlice } from "@reduxjs/toolkit";

export const stateManager = createSlice({
  name: "manager",
  initialState: {
    showFlags: false,
    validateBpn: false,
    message: "",
    status: "",
  },
  reducers: {
    // state to display the list of flags
    showFlags: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes.
      // Also, no return statement is required from these functions.
      state.showFlags = action.payload;
    },
    // state to validate the BPN
    isValidBpn: (state, action) => {
      state.validateBpn = action.payload;
    },
    // status to set the Error or success state
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    // The message to display
    setMessage: (state, action) => {
      state.message = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { isValidBpn, showFlags, setMessage, setStatus } =
  stateManager.actions;
export const selectMessage = (state) => state.manager.message;
export const selectPrint = (state) => state.manager.showFlags;
export const selectStatus = (state) => state.manager.status;
export const selectValidBpn = (state) => state.manager.validateBpn;

export default stateManager.reducer;
