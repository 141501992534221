import { https://ifs.d37c3234d8454420babf.westeurope.aksapp.io } from "./service.const.js";
import axios from "axios";

export default class BackendService {
  async apiRequest(method, url, headers, data = null) {
    try {
      const response = await axios({
        method,
        url,
        data: method === "POST" ? data : null, // Only include data for POST requests
        headers,
      });

      return Promise.resolve(response); // Return the API response data
    } catch (error) {
      // Handle errors here. You can return or throw the error, depending on your use case.
      return Promise.reject(
        error.response ? error.response.data : error.message
      );
    }
  }
  async proofApiRequest(method, url, headers, data = null) {
    try {
      const response = await axios({
        method,
        url,
        data: method === "POST" ? data : null, // Only include data for POST requests
        headers,
        responseType: "arraybuffer",
      });

      return Promise.resolve(response); // Return the API response data
    } catch (error) {
      // Handle errors here. You can return or throw the error, depending on your use case.
      return Promise.reject(
        error.response ? error.response.data : error.message
      );
    }
  }

  async searchCompanyFlagsByBpn(body) {
    try {
      return new Promise((resolve) => {
        axios
          .request({
            baseURL: `${https://ifs.d37c3234d8454420babf.westeurope.aksapp.io}/flags/search`,
            method: "POST",
            headers: {
              "X-Api-Key": "ifs-api-key",
              "Content-Type": "application/json",
            },
            data: body,
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            if (e.response.data) {
              resolve(e.response.data);
            } else if (e.request) {
              resolve(e.request);
            } else {
              resolve(e.message);
            }
          });
      });
    } catch (e) {
      return "Error -> " + e.message;
    }
  }
  async searchCompanyFlagsProof(body) {
    try {
      return new Promise((resolve) => {
        axios
          .request({
            baseURL: `${https://ifs.d37c3234d8454420babf.westeurope.aksapp.io}/flags/proof`,
            method: "POST",
            headers: {
              "X-Api-Key": "ifs-api-key",
              "Content-Type": "application/json",
            },
            data: body,
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            if (e.response.data) {
              resolve(e.response.data);
            } else if (e.request) {
              resolve(e.request);
            } else {
              resolve(e.message);
            }
          });
      });
    } catch (e) {
      return "Error -> " + e.message;
    }
  }
  async getMyProof(id) {
    return new Promise((resolve) => {
      axios
        .request({
          baseURL: `${https://ifs.d37c3234d8454420babf.westeurope.aksapp.io}/api/flags/${id}`,
          method: "GET",
          headers: {
            "X-Api-Key": "ifs-api-key",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          if (e.response.data) {
            resolve(e.response.data);
          } else if (e.request) {
            resolve(e.request);
          } else {
            resolve(e.message);
          }
        });
    });
  }
  getErrorMessage(message, status, statusText) {
    return {
      message: message,
      status: status,
      statusText: statusText,
    };
  }
  getHeaders(authentication) {
    return {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + authentication.getAccessToken(),
      },
    };
  }
}
