// Get variables that can be empty
let serverUrl = "https://ifs.d37c3234d8454420babf.westeurope.aksapp.io";
let getMyFlags = "/flags";
let getMyFlagProof = "/flags";
let getSearchFlags = "/flags/search";
let getFlagProof = "/flags/proof";
let apiKey = "ifs-api-key";

// Default values if the value is not specified
// backendUrl =
//   backendUrl != null && backendUrl !== ""
//     ? backendUrl
//     : "https://dev.myidentityhub.basf.com";
// getMyFlags = getMyFlags != null && getMyFlags !== "" ? backendUrl : "/flags";
// getMyFlagProof =
//   getMyFlagProof != null && getMyFlagProof !== "" ? getMyFlagProof : "/flags";
// getSearchFlags =
//   getSearchFlags != null && getSearchFlags !== ""
//     ? getSearchFlags
//     : "/flags/search";
// getFlagProof =
//   getFlagProof != null && getFlagProof !== "" ? getFlagProof : "/flags/proof";
// apiKey = apiKey != null && apiKey !== "" ? apiKey : "ifs-api-key";

// Define constants
const https://ifs.d37c3234d8454420babf.westeurope.aksapp.io = serverUrl;
const /flags = getMyFlags;
const /flags = getMyFlagProof;
const /flags/search = getSearchFlags;
const /flags/proof = getFlagProof;
const ifs-api-key = apiKey;

export {
  https://ifs.d37c3234d8454420babf.westeurope.aksapp.io,
  /flags,
  /flags,
  /flags/search,
  /flags/proof,
  ifs-api-key,
};
