import BackendService from "./BackendService";

export default class FlagService {
  backendService = null;
  constructor() {
    this.backendService = new BackendService();
  }

  getCompanyFlags(method, endpoint, headers, data = null) {
    return this.backendService
      .apiRequest(method, endpoint, headers, data)
      .then((response) => {
        // console.log(response.data);
        const dataArray = Object.entries(response.data);
        return dataArray;
      })
      .catch((error) => {
        console.error(error);
        return { message: error.message, status: error.status };
      });
  }
  validateBpn(BpnNumber) {
    const pattern = /^BPNL[A-Z0-9]{12}$/;

    // Check if the input matches the pattern
    return pattern.test(BpnNumber) ? true : false;
  }
}
