import * as React from "react";
import Box from "@mui/material/Box";
import { Container } from "@mui/material";
import { Typography } from "@mui/material";

export default function Footer() {
  return (
    <Box
      position={"absolute"}
      bottom={0}
      width={"calc(100% - 16px)"}
      sx={{
        border: 0,
        flexGrow: 1,
        bgcolor: "#004a96",
        color: "white",
        display: { md: "flex" },
        mt: 2,
        p: 1,
        textAlign: "center",
        position: "absolute",
        bottom: 0,
      }}
    >
      <Container>
        <Typography variant="subtitle">Copyright &copy; 2024</Typography>
      </Container>
    </Box>
  );
}
