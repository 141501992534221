import React from "react";
import { Routes, Route } from "react-router-dom";
import MainPage from "../main";
import NotFoundPage from "../views/NotFoundView";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;
