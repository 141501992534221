import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DownloadIcon from "@mui/icons-material/Download";
import { CardHeader } from "@mui/material";
import Box from "@mui/material/Box";
import { setMessage } from "../managers/StateManager";
import { useDispatch } from "react-redux";
import {
  https://ifs.d37c3234d8454420babf.westeurope.aksapp.io,
  ifs-api-key,
  /flags/proof,
  /flags,
} from "../services/service.const";
import DownloadFlagService from "../services/DownloadFlagService";

export default function Flag({ id, flag, bpn }) {
  // create backend service instance to make API calls
  const dispatch = useDispatch();
  const downloadFlagService = new DownloadFlagService();

  const onDownload = async (id) => {
    const headers = {
      "X-Api-Key": ifs-api-key,
    };
    try {
      // Check if bpn value is "My Company Flags", then download my flags
      if (bpn === "My Company Flags") {
        // same company => my company flag
        const downloadUrl = https://ifs.d37c3234d8454420babf.westeurope.aksapp.io + /flags + "/" + id;
        // Trigger download my company flag
        let response = await downloadFlagService.downloadMyCompanyFlag(
          "GET",
          downloadUrl,
          headers,
          id
        );
        if (response.status)
          dispatch(setMessage("The flag " + id + " is being downloaded..!!"));
        else
          dispatch(
            setMessage(
              "There was an error downloading the flag: " + id + "..!!"
            )
          );
      } else {
        // different company => partner company flag
        // Trigger download partner company flag
        const downloadUrl = https://ifs.d37c3234d8454420babf.westeurope.aksapp.io + /flags/proof;
        const data = {
          bpn: bpn,
          id: id,
        };
        let response = await downloadFlagService.downloadPartnerCompanyFlag(
          "POST",
          downloadUrl,
          headers,
          data
        );
        if (response.status)
          dispatch(setMessage("The flag " + id + " is being downloaded..!!"));
        else
          dispatch(
            setMessage(
              "There was an error downloading your partner company flag: " +
                id +
                "..!!"
            )
          );
      }
    } catch (error) {
      console.error("Error downloading flag:", error);
    }
  };

  return (
    <div>
      <Card sx={{ border: 1 }}>
        <CardHeader
          sx={{
            height: 100,
            flexFlow: 0,
          }}
          title={flag.name}
          titleTypographyProps={{ variant: "h6" }}
          subheader={flag.type}
        />

        <CardContent>
          <Box>
            <Typography sx={{ color: "text.secondary" }}>Type</Typography>
            <Typography variant="body2">{flag.type}</Typography>
          </Box>
          <Box sx={{ mt: 1.5 }}>
            <Typography sx={{ color: "text.secondary" }}>ID</Typography>
            <Typography variant="body2">{id}</Typography>
          </Box>
        </CardContent>

        {flag.mimetype !== "Boolean" ? (
          <CardActions>
            <Button
              fullWidth
              variant="outlined"
              onClick={(event) => {
                onDownload(id);
              }}
              endIcon={<DownloadIcon />}
            >
              Download
            </Button>
          </CardActions>
        ) : (
          <CardActions sx={{ height: 37 }}></CardActions>
        )}
      </Card>
    </div>
  );
}
