import * as React from "react";
import "./assets/styles/App.css";
import AppRoutes from "./router/index";

function App() {
  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;
