import React from "react";
import { Container, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Flag from "../components/Flag";
import { useSelector } from "react-redux";
import { selectPrint } from "../managers/StateManager";

export default function FlagsListView({ value, companyFlags }) {
  const showFlags = useSelector(selectPrint);

  return (
    <Container sx={{ border: 0, px: "1rem", pt: "1rem", pb: "5rem" }}>
      {companyFlags.length > 0 ? (
        <Box>
          <Typography
            variant="h5"
            noWrap
            sx={{
              mx: "auto",
              flexGrow: 1,
              fontWeight: 700,
              letterSpacing: ".1rem",
              color: "inherit",
              textDecoration: "none",
              textAlign: "center",
            }}
          >
            {value === "My Company Flags" ? value : value + " Company Flags"}
          </Typography>
        </Box>
      ) : null}
      <br />
      {showFlags ? (
        <Box>
          <Grid container spacing={3}>
            {companyFlags.map(([id, data]) => (
              <Grid item key={id} xs={12} md={4} lg={3}>
                <Flag id={id} flag={data} bpn={value}></Flag>
                {/* {value === "My Company Flags" ? (
                  <MyCompanyFlag id={id} flag={data} bpn={value} />
                ) : (
                  <CompanyFlag id={id} flag={data} bpn={value} />
                )} */}
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : null}
    </Container>
  );
}
